import request from '@/utils/request';

export const searchUsers =
  (q, my_downline = false, excluded_ids = [], custom_params = {}, active_user = false, limit = 20) =>
  async () =>
    await request({
      path: `/v1/search/users`,
      query: { q, limit, active_user, my_downline, excluded_ids, ...custom_params }
    });

export const searchContacts = query => async () =>
  await request({
    path: `/v1/search/contacts`,
    query
  });

export const searchDrugNames = query => async () =>
  await request({
    path: `/v1/search/drug_names`,
    query
  });

export const searchDuplicateContacts = query => async () =>
  await request({
    path: `/v1/search/duplicate_contacts`,
    query
  });

export const searchImportUsers = (q, filter_by) => async () =>
  await request({
    path: `/v1/search/import_attempt_users`,
    query: { q, filter_by }
  });

export const searchImportPools = q => async () =>
  await request({
    path: `/v1/search/import_attempt_pools`,
    query: { q }
  });

export const searchVendors = () => async () =>
  await request({
    path: `/v1/fire_light/vendors`
  });

export const searchProducts = id => async () =>
  await request({
    path: `/v1/fire_light/products/${id}`
  });

export const searchCounties = query => async () =>
  await request({
    path: `/v1/search/counties`,
    query
  });

export const searchApiTrackingVendors = query => async () =>
  await request({
    path: `/v1/search/api_vendors`,
    query
  });
