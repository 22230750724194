import React from 'react';
import { withErrorBoundary } from '@/utils/errors';

import styles from '@/styles/shared/SmallModal.module.scss';

const TrainingCertification = ({ onSucess, onClose }) => (
  <div className={styles.smallModal}>
    <div className={styles.smallModalContent}>
      <h5 className={styles.title}>Start the MAX Training Certification Program Now!</h5>
      <div className={styles.buttonsWrapper}>
        <button onClick={onClose} className="button is-large is-secondary">
          <p>Cancel</p>
        </button>
        <button onClick={onSucess} className="button is-large is-primary">
          <p>Start Training</p>
        </button>
      </div>
    </div>
  </div>
);

export default withErrorBoundary(TrainingCertification);
