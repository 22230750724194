export const THEME_DEFAULT = 'default';
export const THEME_APPLE = 'apple';
export const THEME_MATERIAL = 'material';
export const THEME_METRO = 'metro';
export const THEME_TAILWINDS = 'tailwinds';

export const PALETTES = [
  { value: 'default', label: 'Default' },
  { value: 'metro', label: 'Windows Metro' },
  { value: 'material', label: 'Google Material' },
  { value: 'apple', label: 'Apple Foundations' },
  { value: 'tailwinds', label: 'Tailwinds' }
];
