import React from 'react';
import { withErrorBoundary } from '@/utils/errors';
import classnames from 'classnames';

const Modal = ({ classNames, header, footer, children, onClose, footerContent, buttons, onSubmit }) => {
  return (
    <div className={classnames('custom-modal modal is-active', classNames)}>
      <div onClick={onClose} className="modal-background"></div>
      <div className="modal-card">
        {header && (
          <header className="modal-card-head">
            <h5 className="modal-card-title" style={{ textAlign: 'center' }}>
              {header}
            </h5>
            <span className="mbri-close" onClick={onClose}></span>
          </header>
        )}
        <section className="modal-card-body">{children}</section>
        {footer && (
          <footer className="modal-card-foot">
            {footerContent && <div className="content">{footerContent}</div>}
            {buttons && (
              <div className="buttons">
                <button onClick={onClose} className="button">
                  <span>Cancel</span>
                </button>
                <button onClick={onSubmit} className="button is-success">
                  <span>Save</span>
                </button>
              </div>
            )}
          </footer>
        )}
      </div>
    </div>
  );
};

export default withErrorBoundary(Modal);
