export const pageview = url => {
  window.gtag('config', process.env.GOOGLE_ANALYTICS, {
    page_title: getTitle(url),
    page_path: url
  });
};

export const event = ({ action, params }) => {
  window.gtag('event', action, params);
};

export const setuser = user => {
  if (user && user.id && user.username) {
    window.gtag('config', process.env.GOOGLE_ANALYTICS, {
      user_identifier: user?.id,
      user_name: user?.username,
      user_number: user?.number,
      user_company: user?.company?.name,
      user_type: user?.profile?.account_type
    });
  }
};

const getTitle = path => path.substring(path.lastIndexOf('/') + 1);
