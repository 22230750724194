import React, { Component } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import classNames from 'classnames';
import { publish } from '@/utils/publish';

import { setCurrentUserId } from '@/utils/storage';

import { setLoading, setLoadingRouter } from '@/store/actions/session.actions';
import { logOut } from '@/store/actions/session.actions';
import { updateTos } from '@/store/actions/currentUser.actions';

import Header from '@/components/Layout/Header';
import Notification from '@/components/Base/Notification';
import Modal from '@/components/Base/Modal';
import TermsSubmission from '@/components/Profile/TermsSubmission';

//loaders
import Loader from '@/components/Helpers/Loader';
import { getLoginStack } from '@/utils/storage';

// import Footer from '@/components/Layout/Footer';

const DESKTOP_SCREEN = '1280';
const MOBILE_BREAKPOINT = 1240;
const IPAD_PRO = [834, 1024, 1194];

class Wrapper extends Component {
  state = {
    confirmationModalVisible: false
  };

  async componentDidMount() {
    window.addEventListener('resize', this.changeViewport); // for responsive
    await this.props.setLoading(false);
    setCurrentUserId(this.props.currentUser.id); // should be localStorage
    const lastLogin = getLoginStack().peek();
    if (!this.props.currentUser.updated_tos && lastLogin !== 'logged_as_admin') {
      this.setState({ confirmationModalVisible: true });
    }
    await this.changeViewport();

    // this.props.router.events.on('routeChangeStart', this.handleRouteChangeStart);
    // this.props.router.events.on('routeChangeComplete', this.handleRouteChangeComplete);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.changeViewport);

    // this.props.router.events.on('routeChangeStart', this.handleRouteChangeStart);
    // this.props.router.events.on('routeChangeComplete', this.handleRouteChangeComplete);
  }

  onSubmit = () => {
    this.props
      .updateTos()
      .then(() => this.setState({ confirmationModalVisible: false }))
      .then(() => window.location.reload())
      .catch(res => publish('error', res));
  };

  onCancel = () => {
    this.props.logOut();
    this.setState({ confirmationModalVisible: false });
  };

  changeViewport = () => {
    const viewportWidth =
      IPAD_PRO.includes(window.outerWidth) || window.outerWidth > MOBILE_BREAKPOINT ? 'device-width' : DESKTOP_SCREEN;
    document.getElementById('viewport')?.setAttribute('content', `initial-scale=1.0, width=${viewportWidth}`);
  };

  handleRouteChangeStart = () => this.props.setLoadingRouter(true);
  handleRouteChangeComplete = () => this.props.setLoadingRouter(false);

  get containerStyles() {
    if (this.props.containerBigFullWidth) return 'container-large-full';
    if (this.props.containerMediumFullWidth) return 'container-medium-full';

    return this.props.containerFullWidth ? 'container container-full' : 'container custom-container';
  }

  render() {
    return (
      <div className={classNames('wrapper', this.props.fontSize, this.props.primaryColor, this.props.background)}>
        {this.props.loading || this.state.confirmationModalVisible ? (
          <Loader loading />
        ) : (
          <>
            <Header setActiveTab={this.props.setActiveTab} quickSearchContacts={this.props.quickSearchContacts} />
            <div className="section custom-section">
              <div className={this.containerStyles}>
                {this.props.loading_router ? <Loader loading /> : this.props.children}
              </div>
            </div>
          </>
        )}

        {this.state.confirmationModalVisible && (
          <Modal classNames={'delete-modal'}>
            <TermsSubmission onSubmit={this.onSubmit} onCancel={this.onCancel} />
          </Modal>
        )}

        {/* <Footer /> */}
        <Notification />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fontSize: state.currentUser.profile.font_size,
  primaryColor: state.currentUser.profile.primary_color,
  background: state.currentUser.profile.background,
  currentUser: state.currentUser,
  loading: state.session.loading,
  loading_router: state.session.loading_router
});

export default withRouter(
  connect(mapStateToProps, { setLoading, setLoadingRouter, logOut, updateTos })(withErrorBoundary(Wrapper))
);
