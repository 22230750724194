import React from 'react';
import { withErrorBoundary } from '@/utils/errors';
import Link from 'next/link';

import styles from '@/styles/shared/SmallModal.module.scss';

const TermsSubmission = ({ onSubmit, onCancel }) => (
  <div className={styles.smallModal}>
    <div className={styles.smallModalContent}>
      <h5 className={styles.title}>Privacy Policy & Terms of Use</h5>
      <p className={styles.description}>
        By accessing or using this website, you accept the following updated Terms of Use and Privacy Policy. Click on
        the links below to review details to confirm acceptance.
        <br />
        <b>
          <Link replace href="/terms-of-use">
            Terms of Use
          </Link>
        </b>{' '}
        and{' '}
        <b>
          <Link replace href="/privacy-policy">
            Privacy Policy
          </Link>
        </b>
      </p>
      <div className={styles.buttonsWrapper}>
        <button onClick={onCancel} className="button is-large is-secondary">
          <p>Cancel</p>
        </button>
        <button onClick={onSubmit} className="button is-large is-primary">
          <p>Confirm</p>
        </button>
      </div>
    </div>
  </div>
);

export default withErrorBoundary(TermsSubmission);
