import React, { Component } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { publish } from '@/utils/publish';
import Select from 'react-select';
import styles from './ThemeSwitcher.module.scss';

import {
  FONT_SIZE_SMALL,
  FONT_SIZE_DEFAULT,
  FONT_SIZE_LARGE,
  PRIMARY_COLOR_ORANGE,
  PRIMARY_COLOR_GREEN,
  PRIMARY_COLOR_PINK,
  PRIMARY_COLOR_YELLOW,
  PRIMARY_COLOR_PURPLE,
  BACKGROUND_DEFAULT,
  BACKGROUND_DARK
} from '@/store/actions/theme.actions';

import { PALETTES } from '@/store/models/theme';

import Submit from '@/components/Forms/Submit';
import { updateTheme } from '@/store/actions/currentUser.actions';
import { setLoading } from '@/store/actions/session.actions';

class ThemeSwitcher extends Component {
  state = {
    fontSize: this.props.fontSize,
    primaryColor: this.props.primaryColor,
    background: this.props.background,
    theme: this.props.theme || 'default'
  };

  get header() {
    return (
      <div className={styles.switcherHeader}>
        <h5>Quickly customize your view</h5>
        <p>You can change these settings at any time</p>
      </div>
    );
  }

  get fonts() {
    return (
      <div className={styles.fonts}>
        <p className={styles.title}>Font size</p>
        <div className={styles.fontSizes}>
          <p
            onClick={() => this.setState({ fontSize: FONT_SIZE_SMALL })}
            className={classNames(styles.small, this.state.fontSize === FONT_SIZE_SMALL && styles.active)}
          >
            aA
          </p>
          <p
            onClick={() => this.setState({ fontSize: FONT_SIZE_DEFAULT })}
            className={classNames(styles.default, this.state.fontSize === FONT_SIZE_DEFAULT && styles.active)}
          >
            aA
          </p>
          <p
            onClick={() => this.setState({ fontSize: FONT_SIZE_LARGE })}
            className={classNames(styles.large, this.state.fontSize === FONT_SIZE_LARGE && styles.active)}
          >
            aA
          </p>
        </div>
      </div>
    );
  }

  get colors() {
    let theme = this.state.theme ? this.state.theme : 'default';

    return (
      <div className={styles.colors}>
        <p className={styles.title}>Color</p>
        <div className={styles.colorsOptions}>
          <div
            onClick={() => this.setState({ primaryColor: PRIMARY_COLOR_ORANGE })}
            className={classNames(
              styles.oval,
              styles[`${theme}-orange`],
              this.state.primaryColor === PRIMARY_COLOR_ORANGE && styles.active
            )}
          >
            <span className="mbri-success"></span>
          </div>
          <div
            onClick={() => this.setState({ primaryColor: PRIMARY_COLOR_GREEN })}
            className={classNames(
              styles.oval,
              styles[`${theme}-green`],
              this.state.primaryColor === PRIMARY_COLOR_GREEN && styles.active
            )}
          >
            <span className="mbri-success"></span>
          </div>
          <div
            onClick={() => this.setState({ primaryColor: PRIMARY_COLOR_PINK })}
            className={classNames(
              styles.oval,
              styles[`${theme}-pink`],
              this.state.primaryColor === PRIMARY_COLOR_PINK && styles.active
            )}
          >
            <span className="mbri-success"></span>
          </div>
          <div
            onClick={() => this.setState({ primaryColor: PRIMARY_COLOR_YELLOW })}
            className={classNames(
              styles.oval,
              styles[`${theme}-yellow`],
              this.state.primaryColor === PRIMARY_COLOR_YELLOW && styles.active
            )}
          >
            <span className="mbri-success"></span>
          </div>
          <div
            onClick={() => this.setState({ primaryColor: PRIMARY_COLOR_PURPLE })}
            className={classNames(
              styles.oval,
              styles[`${theme}-purple`],
              this.state.primaryColor === PRIMARY_COLOR_PURPLE && styles.active
            )}
          >
            <span className="mbri-success"></span>
          </div>
        </div>
      </div>
    );
  }

  get palette() {
    const theme = this.state.theme;

    return (
      <div className={styles.palette}>
        <p className={styles.title}>Color Palette</p>
        <div className={styles.paletteOptions}>
          <Select
            id="theme"
            name="theme"
            isSearchable={false}
            className="custom-select"
            classNamePrefix="custom-select"
            value={PALETTES.find(({ value }) => value == theme)}
            options={PALETTES}
            onChange={this.onChangeTheme}
          />
        </div>
      </div>
    );
  }

  get background() {
    return (
      <div className={styles.background}>
        <p className={styles.title}>Background</p>
        <div className={styles.backgroundsWrapper}>
          <div className={classNames('field', styles.field)}>
            <input
              className="is-checkradio is-large"
              id="default"
              type="checkbox"
              name="default"
              onChange={() => this.setState({ background: BACKGROUND_DEFAULT })}
              checked={this.state.background === BACKGROUND_DEFAULT}
            />
            <label htmlFor="default">
              <p>Default</p>
            </label>
          </div>
          <div className={classNames('field', styles.field)}>
            <input
              className="is-checkradio is-large"
              id="dark"
              type="checkbox"
              name="dark"
              onChange={() => this.setState({ background: BACKGROUND_DARK })}
              checked={this.state.background === BACKGROUND_DARK}
            />
            <label htmlFor="dark">
              <p>Dark</p>
            </label>
          </div>
        </div>
      </div>
    );
  }

  onChangeTheme = themeObj => this.setState({ theme: themeObj.value });

  onSubmit = async (e, callback) => {
    e.preventDefault();
    const themePage = this.state.theme !== this.props.theme;
    const backgroundPage = this.state.background !== this.props.background;
    if (backgroundPage) {
      await this.props.setLoading(true);
    }
    await this.props
      .updateTheme({
        profile: {
          font_size: this.state.fontSize,
          primary_color: this.state.primaryColor,
          background: this.state.background,
          theme: this.state.theme ? this.state.theme : 'default'
        }
      })
      .then(() => {
        if (themePage) {
          window.location.reload();
        } else {
          this.props.onClose();
        }
        publish('success', 'Your changes were successfully saved.');
      })
      .catch(res => publish('error', res));
    if (backgroundPage) {
      await this.props.setLoading(false);
    }

    callback();
  };

  render() {
    return (
      <div className={styles.switcherWrapper}>
        <div className={styles.switcher}>
          {this.header}
          {this.fonts}
          {this.colors}
          {this.palette}
          {this.background}
          <div className={styles.switcherFooter}>
            <Submit label="Save" className="button is-large is-primary" onSubmit={this.onSubmit} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fontSize: state.currentUser.profile.font_size,
  primaryColor: state.currentUser.profile.primary_color,
  background: state.currentUser.profile.background,
  theme: state.currentUser.profile.theme
});

export default connect(mapStateToProps, { updateTheme, setLoading })(withErrorBoundary(ThemeSwitcher));
