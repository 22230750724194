import React from 'react';
import { withErrorBoundary } from '@/utils/errors';

import styles from '@/components/Layout/Header.module.scss';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
import { config } from '@/config';
import { useUser } from '@auth0/nextjs-auth0/client';

const LogoutButton = ({ logOut }) => {
  if (config().SSO_FEATURE) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { user } = useUser();

    if (user) {
      return (
        <div className={styles.navbarItem}>
          <a href={`/api/auth/logout?returnTo=${publicRuntimeConfig.APP_URL}/sso-logout`}>
            <p>Log off</p>
          </a>
        </div>
      );
    }
  }

  return (
    <div onClick={logOut} className={styles.navbarItem}>
      <a>
        <p>Log off</p>
      </a>
    </div>
  );
};

export default withErrorBoundary(LogoutButton);
