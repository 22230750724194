import { RRule } from 'rrule';

export const EVENT_APPOINTMENT = 'Event::Appointment';
export const EVENT_FOLLOW_UP = 'Event::FollowUp';
export const EVENT_TASK = 'Event::Task';
export const EVENT_OTHER = 'Event::Other';
export const EVENT_UNAVAILABLE = 'Event::Unavailable';
export const EVENT_COPY = 'Event::Copy';

export const TYPES = [
  { value: EVENT_APPOINTMENT, label: 'Appointment' },
  { value: EVENT_FOLLOW_UP, label: 'Follow Up' },
  { value: EVENT_TASK, label: 'Task' },
  { value: EVENT_OTHER, label: 'Other' },
  { value: EVENT_UNAVAILABLE, label: 'Unavailable' },
  { value: EVENT_COPY, label: 'Copy' }
];

export const RRULE_WEEK_DAYS = [RRule.SU, RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA];
export const RRULE_WEEK_LABELS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const EVENT_THEMES = {
  default: {
    orange: '#ff4113',
    green: '#00f4bd',
    pink: '#ff006f',
    yellow: '#ffb900',
    purple: '#5300ff'
  },
  metro: {
    orange: '#da532c',
    green: '#1e7145',
    pink: '#b91d47',
    yellow: '#e3a21a',
    purple: '#7e3878'
  },
  material: {
    orange: '#FF5722',
    green: '#4CAF50',
    pink: '#E91E63',
    yellow: '#FFC107',
    purple: '#673AB7'
  },
  apple: {
    orange: '#ff9500',
    green: '#4cd964',
    pink: '#fa2c55',
    yellow: '#facc01',
    purple: '#5856d6'
  },
  tailwinds: {
    orange: '#f97316',
    green: '#22c55e',
    pink: '#ec4899',
    yellow: '#eab308',
    purple: '#a855f7'
  }
};

export const DEFAULT_COLORS = {
  red: '#FF4113',
  light_green: '#00f4bd',
  pink: '#FF006F',
  yellow: '#ffb900',
  orange: '#F39500',
  green: '#02BE4D',
  purple: '#D460E0',
  blue: '#0075C2',
  cyan: '#00C5E4',
  dark_blue: '#334364',
  gray: '#B2BAC6'
};

export const MATERIAL_COLORS = {
  red: '#FF5722', // orange
  light_green: '#4CAF50', // green
  pink: '#E91E63',
  yellow: '#FFC107',
  orange: '#FF9800', // peach
  green: '#1B5E20', // dark green
  purple: '#9C27B0', // violet
  blue: '#3F51B5',
  cyan: '#2196F3', // light blue
  dark_blue: '#303F9F',
  gray: '#B2BAC6'
};

export const METRO_COLORS = {
  red: '#da532c',
  light_green: '#1e7145',
  pink: '#b91d47',
  yellow: '#e3a21a',
  orange: '#ffc40d',
  green: '#1B5E20',
  purple: '#9f00a7',
  blue: '#2b5797',
  cyan: '#608ed2',
  dark_blue: '#224477',
  gray: '#B2BAC6'
};

export const TAILWINDS_COLORS = {
  red: '#f97316',
  light_green: '#22c55e',
  pink: '#ec4899',
  yellow: '#eab308',
  orange: '#fbbf24',
  green: '#166534',
  purple: '#a78bfa',
  blue: '#3b82f6',
  cyan: '#60a5fa',
  dark_blue: '#2563eb',
  gray: '#B2BAC6'
};

export const APPLE_COLORS = {
  red: '#ff9500',
  light_green: '#4cd964',
  pink: '#fa2c55',
  yellow: '#facc01',
  orange: '#fb625a',
  green: '#1e5728',
  purple: '#8a89e2',
  blue: '#377bff',
  cyan: '#5ac8fa',
  dark_blue: '#214a99',
  gray: '#B2BAC6'
};
