import React, { Component } from 'react';
import { withErrorBoundary } from '@/utils/errors';

import classNames from 'classnames';
import Submit from '@/components/Forms/Submit';
import { PatternFormat } from 'react-number-format';
import InputMask from 'react-input-mask';

import styles from './EventDetailModal.module.scss';

class EventDetailModal extends Component {
  render() {
    const { onChangeEventDetail, event_detail, hasError, onSubmit } = this.props;
    return (
      <div className={styles.eventDetailModalWrapper}>
        <form autoComplete="off" name="eventForm" className={styles.eventDetailFormWrapper}>
          <div className={styles.eventDetailForm}>
            <div className={styles.formGroup}>
              <div className={styles.row}>
                <div className={classNames('control', styles.control)}>
                  <label htmlFor="event_detail.first_name" className="required-field">
                    <p className="astericks">*</p>
                    <p className={classNames('title head', styles.title, styles.head)}>First Name</p>
                  </label>
                  <input
                    name="event_detail.first_name"
                    onChange={onChangeEventDetail}
                    type="text"
                    className={classNames('input', {
                      'is-danger': hasError('eventForm', 'event_detail.first_name', 'required')
                    })}
                    data-validate='["required"]'
                    value={event_detail.first_name}
                  />
                  {hasError('eventForm', 'event_detail.first_name', 'required') && (
                    <p className="help is-danger error">Field is required</p>
                  )}
                </div>
                <div className={classNames('control', styles.control)}>
                  <label htmlFor="event_detail.last_name" className="required-field">
                    <p className="astericks">*</p>
                    <p className={classNames('title head', styles.title, styles.head)}>Last Name</p>
                  </label>
                  <input
                    name="event_detail.last_name"
                    onChange={onChangeEventDetail}
                    className={classNames('input', {
                      'is-danger': hasError('eventForm', 'event_detail.last_name', 'required')
                    })}
                    data-validate='["required"]'
                    type="text"
                    value={event_detail.last_name}
                  />
                  {hasError('eventForm', 'event_detail.last_name', 'required') && (
                    <p className="help is-danger error">Field is required</p>
                  )}
                </div>
              </div>
            </div>

            <p className={styles.formGroupTitle}>ADDRESS INFO</p>
            <div className={styles.formGroup}>
              <div className={styles.row}>
                <div className={classNames('control', styles.control)}>
                  <label htmlFor="event_detail.address" className="required-field">
                    <p className={classNames('title head', styles.title, styles.head)}>Address line 1</p>
                  </label>
                  <input
                    name="event_detail.address"
                    onChange={onChangeEventDetail}
                    className="input"
                    maxLength="30"
                    type="text"
                    value={event_detail.address}
                  />
                </div>

                <div className={classNames('control', styles.control)}>
                  <label htmlFor="event_detail.address_2" className="required-field">
                    <p className={classNames('title head', styles.title, styles.head)}>Address line 2</p>
                  </label>
                  <input
                    name="event_detail.address_2"
                    onChange={onChangeEventDetail}
                    className="input"
                    maxLength="30"
                    type="text"
                    value={event_detail.address_2}
                  />
                </div>
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.row}>
                <div className={classNames('control', styles.control)}>
                  <label htmlFor="event_detail.city" className="required-field">
                    <p className={classNames('title head', styles.title, styles.head)}>City</p>
                  </label>
                  <input
                    name="event_detail.city"
                    onChange={onChangeEventDetail}
                    className="input"
                    maxLength="30"
                    type="text"
                    value={event_detail.city}
                  />
                </div>

                <div className={classNames('control', styles.control)}>
                  <label htmlFor="event_detail.state" className="required-field">
                    <p className={classNames('title head', styles.title, styles.head)}>State</p>
                  </label>
                  <input
                    name="event_detail.state"
                    className="input is-large"
                    type="text"
                    onChange={onChangeEventDetail}
                    value={event_detail.state}
                  />
                </div>
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.row}>
                <div className={classNames('control', styles.control)}>
                  <label htmlFor="event_detail.zip_code" className="required-field">
                    <p className={classNames('title head', styles.title, styles.head)}>ZIP Code</p>
                  </label>
                  <InputMask
                    id="zip_code"
                    name="event_detail.zip_code"
                    className="input is-large"
                    onChange={onChangeEventDetail}
                    value={event_detail.zip_code}
                    mask="*****-****"
                    maskChar={' '}
                  />
                </div>
                <div className={classNames('control', styles.control)}>
                  <label htmlFor="event_detail.country" className="required-field">
                    <p className={classNames('title head', styles.title, styles.head)}>County</p>
                  </label>
                  <input
                    name="event_detail.country"
                    className="input is-large"
                    type="text"
                    onChange={onChangeEventDetail}
                    value={event_detail.country}
                  />
                </div>
              </div>
            </div>

            <p className={styles.formGroupTitle}>CONTACT INFO</p>
            <div className={styles.formGroup}>
              <div className={styles.row}>
                <div className={classNames('control', styles.control)}>
                  <label htmlFor="event_detail.phone" className="required-field">
                    <p className={classNames('title head', styles.title, styles.head)}>Phone</p>
                  </label>
                  <PatternFormat
                    name="event_detail.phone"
                    className="input is-large"
                    format="###-###-####-####"
                    onChange={onChangeEventDetail}
                    value={event_detail.phone}
                  />
                </div>

                <div className={classNames('control', styles.control)}>
                  <label htmlFor="event_detail.cell_phone" className="required-field">
                    <p className={classNames('title head', styles.title, styles.head)}>Mobile</p>
                  </label>
                  <PatternFormat
                    name="event_detail.cell_phone"
                    className="input is-large"
                    format="###-###-####-####"
                    onChange={onChangeEventDetail}
                    value={event_detail.cell_phone}
                  />
                </div>
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.row}>
                <div className={classNames('control', styles.control)}>
                  <label htmlFor="event_detail.email" className="required-field">
                    <p className="astericks">*</p>
                    <p className={classNames('title head', styles.title, styles.head)}>Email</p>
                  </label>
                  <input
                    name="event_detail.email"
                    onChange={onChangeEventDetail}
                    className={classNames('input', {
                      'is-danger':
                        (event_detail.email && hasError('eventForm', 'event_detail.email', 'email')) ||
                        hasError('eventForm', 'event_detail.email', 'required')
                    })}
                    type="email"
                    data-validate='["required", "email"]'
                    value={event_detail.email}
                  />
                  {hasError('eventForm', 'event_detail.email', 'required') && (
                    <p className="help is-danger error">Field is required</p>
                  )}
                  {event_detail.email && hasError('eventForm', 'event_detail.email', 'email') && (
                    <p className="help is-danger error">Invalid email address format</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.eventDetailModalFooter}>
            <div className="buttons">
              <button onClick={this.props.onClose} className="button is-large is-secondary">
                <span>Cancel</span>
              </button>
              <Submit
                label="Save"
                id="redirectSubmit"
                className={classNames('button is-success', styles.isSuccess)}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withErrorBoundary(EventDetailModal);
